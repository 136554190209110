import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const ApplicationsCreate = (props) => {
  const filterAssignment = (searchText) => {
    return {
      '$expert.id$': parseInt(searchText),
      '$expert.nickname$': searchText,
      _autocomplete: true,
    };
  };
  return (
    <Create {...props} title='Create Application'>
      <SimpleForm
        variant='standard'
        margin='normal'
        toolbar={<CreateToolbar />}
      >
        <ReferenceInput
          validate={required()}
          source='brandId'
          reference='brands'
          label='Owner'
          perPage={10}
          filterToQuery={(searchText) => ({
            id: parseInt(searchText),
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'id', order: 'ASC' }}
          fullWidth
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(choice) =>
              choice?.id ? `${choice.name} (${choice.id})` : 'None'
            }
          />
        </ReferenceInput>
        <ReferenceInput
          source='expert_id'
          reference='experts'
          label='Expert'
          validate={required()}
          perPage={25}
          filterToQuery={(searchText) => filterAssignment(searchText)}
          fullWidth
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(choice) => {
              const choiceName = choice?.name
                ? choice.name
                : `${choice?.nickname}`;
              return `${choiceName}  (${choice?.id})`;
            }}
          />
        </ReferenceInput>
        {/* <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              source="reviewable_id"
              reference="experts"
              label="Expert"
              validate={required()}
              perPage={25}
              filterToQuery={(searchText) => filterAssignment(searchText)}
              fullWidth
            >
              <AutocompleteInput
                matchSuggestion={() => true}
                optionText={(choice) => {
                  return `${choice?.name} (${choice?.id})`;
                }}
              />
            </ReferenceInput>
          )}
        </FormDataConsumer> */}
        <TextInput source='name' validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  );
};
