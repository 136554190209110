import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import { List, ChipField, FunctionField } from 'react-admin';
import BrandsFilter from './filter';
import { AvatarContainer } from '../../../components/Avatars/AvatarContainer';
import { ExpertAvatar } from 'components/Avatars';

export const BrandsList = (props) => (
  <List
    title='Brands'
    filters={<BrandsFilter />}
    pagination={<Paginator />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Brands' />}
  >
    <DataGridList show edit destroy>
      <FunctionField
        label='Name'
        sortBy='name'
        render={(record) => (
          <AvatarContainer>
            <ExpertAvatar
              url={record?.thumbnail_url || null}
              status={record.status}
            />
            {record.name}
          </AvatarContainer>
        )}
      />{' '}
      <FunctionField
        sortBy='status'
        label='Status'
        render={(record) => {
          switch (record.status) {
            case 'active':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#44b700',
                  }}
                />
              );

            case 'inactive':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#e62424',
                  }}
                />
              );

            case 'pending':
            default:
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#eead2d',
                  }}
                />
              );
          }
        }}
      />{' '}
    </DataGridList>
  </List>
);
