import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { set } from 'lodash';
import { uploadFile, uploadLargeFile } from 'providers/FileUploader';
import { useCallback, useState } from 'react';
import {
  SaveButton,
  useCreate,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    marginTop: '-3px',
  },
}));

export const UploadAndSave = (props) => {
  const classes = useStyles();
  const { basePath } = props;
  const resource = basePath.split('/').pop();
  const [create] = useCreate(resource);
  const [update] = useUpdate(resource);
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [disableButton, setDisableButton] = useState(false);

  /**
   * Returns needed brand parameters to generate the file key
   */
  const getBrandParameters = (values) => {
    let brandId;
    let brandName; // brand name will be used if its a create operation and theres no id

    switch (resource) {
      case 'brands':
        brandId = values.id;
        brandName = values.name;
        break;
      case 'experts':
        if (values.applications && values.applications.length > 0) {
          brandId = values.applications[0].brand_id;
        }
        break;
      default:
        brandId = values.brand_id;
        break;
    }

    return { brand_id: brandId, brand_name: brandName };
  };

  const handleSave = useCallback(
    async (values, redirect) => {
      setDisableButton(true);
      const keys = {};
      if (values.id || !values.thumbnail || values?.thumbnail_file?.rawFile) {
        const brandParameters = getBrandParameters(values);

        for (let [attribute, value] of Object.entries(values)) {
          try {
            if (attribute.includes('_file')) {
              const originalAttributeName = attribute.split('_file').shift();
              if (Array.isArray(value)) {
                keys[originalAttributeName] = [];
                const files = value;
                for (const file of files) {
                  keys[originalAttributeName] = await uploadLargeFile(
                    originalAttributeName,
                    file,
                    resource,
                    brandParameters,
                  );
                }
              } else {
                const file = value;
                keys[originalAttributeName] = await uploadFile(
                  originalAttributeName,
                  file,
                  resource,
                  brandParameters,
                );
              }
              keys[attribute] = undefined;
            } else {
              if (
                Array.isArray(value) &&
                value.every((obj) => typeof obj === 'object' && obj !== null)
              ) {
                const parentAttribute = attribute;
                for (let [index, object] of value.entries()) {
                  for (let [attribute, value] of Object.entries(object)) {
                    if (attribute.includes('_file')) {
                      const originalAttributeName = attribute
                        .split('_file')
                        .shift();

                      if (Array.isArray(value)) {
                        keys[originalAttributeName] = [];
                        const files = value;
                        for (const file of files) {
                          const upload_file = await uploadLargeFile(
                            originalAttributeName,
                            file,
                            parentAttribute,
                            brandParameters,
                          );
                          set(
                            values,
                            `${parentAttribute}[${index}].${originalAttributeName}`,
                            upload_file,
                          );
                          set(
                            values,
                            `${parentAttribute}[${index}].${attribute}`,
                            null,
                          );
                        }
                      } else {
                        const file = value;
                        const upload_file = await uploadFile(
                          originalAttributeName,
                          file,
                          parentAttribute,
                          brandParameters,
                        );
                        set(
                          values,
                          `${parentAttribute}[${index}].${originalAttributeName}`,
                          upload_file,
                        );
                        set(
                          values,
                          `${parentAttribute}[${index}].${attribute}`,
                          null,
                        );
                      }

                      keys[attribute] = null;
                    }
                  }
                }
              }
            }
          } catch (error) {
            notify(
              `Error uploading image: ${attribute}. Will proceed with saving process, please try to upload the image again later.`,
              {
                type: 'error',
              },
            );
            console.error(`Error uploading image: ${attribute}. Error:`, error);
            continue;
          }
        }

        // in case of cloning:
      } else if (values.thumbnail) {
        // if the thumbnail_file is null, it means that the user cloned a listing and removed it's image:
        if (!values.thumbnail_file) {
          values.thumbnail = null;
        } else {
          values.thumbnail = values.thumbnail.key;
        }
      }

      const id = values.id;
      let finalData = { ...values, ...keys };

      // in case the user is adding an email attachment for an event product,
      // here we're adding the file type to the file key than we send to the backend
      // e.g: email_attachment: 12sads-asd23_sadd-asddsad-dsa.pdf
      if (keys?.email_attachment && values?.email_attachment_file?.rawFile) {
        let fileType =
          '.' + values.email_attachment_file.rawFile.type.split('/').pop();
        keys.email_attachment = keys.email_attachment.concat(fileType);
        finalData.email_attachment = keys.email_attachment;
      }

      if (id) {
        update(
          {
            payload: { id, data: finalData },
          },
          {
            onSuccess: ({ data: record }) => {
              setDisableButton(false);
              notify('ra.notification.updated', 'info', {
                smart_count: 1,
              });
              redirectTo(redirect, basePath, record.id, record);
            },
            onFailure: (error) => {
              setDisableButton(false);
              notify(error.message, 'warning', {
                smart_count: 1,
              });
            },
          },
        );
      } else {
        create(
          {
            payload: { data: { ...values, ...keys } },
          },
          {
            onSuccess: ({ data: newRecord }) => {
              setDisableButton(false);
              notify('ra.notification.created', 'info', {
                smart_count: 1,
              });
              redirectTo(redirect, basePath, newRecord.id, newRecord);
            },
            onFailure: (error) => {
              setDisableButton(false);
              notify(error.message, 'warning', {
                smart_count: 1,
              });
            },
          },
        );
      }
    },

    [create, update, notify, redirectTo, basePath], // eslint-disable-line
  );

  return (
    <SaveButton
      {...props}
      onSave={handleSave}
      disabled={disableButton}
      icon={
        <>
          {disableButton ? (
            <CircularProgress
              className={classes.icon}
              size={18}
              thickness={2}
            />
          ) : (
            <SaveIcon className={classes.icon} fontSize='small' />
          )}
        </>
      }
    />
  );
};
