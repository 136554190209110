import applicationsIcon from '@material-ui/icons/Label';
import { ApplicationsList } from './list';
import { ApplicationsCreate } from './create';
import { ApplicationsEdit } from './edit';
import { ApplicationsShow } from './show';

const ApplicationViews = {
  key: 'applications',
  name: 'applications',
  options: { label: 'Applications', menuParent: 'expert' },
  icon: applicationsIcon,
  list: ApplicationsList,
  create: ApplicationsCreate,
  edit: ApplicationsEdit,
  show: ApplicationsShow,
};

export default ApplicationViews;
