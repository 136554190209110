import { DateFieldShow } from 'components/Fields';
import { VideoComponent } from 'components/ImageContainer/VideoComponent';
import React from 'react';
import {
  ChipField,
  Datagrid,
  EmailField,
  FunctionField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  SimpleShowLayout,
  UrlField,
  BooleanField,
} from 'react-admin';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { AvatarContainer, UserAvatar } from 'components/Avatars';

export const ExpertsShow = (props) => {
  return (
    <Show title={<ViewTitle source='name' />} {...props}>
      <TabbedShowLayout>
        <Tab label='Personal Info'>
          <TextField source='id' label='ID' />
          <TextField source='nickname' label='Display Name' />
          <EmailField source='email' label='Contact Email' />
          <TextField source='public_id' label='Wallet Address' />
          <TextField source='phone_number' label='Phone Number' />
          <ImageField source='thumbnail_url' label='Thumbnail' />
          <FunctionField
            label='Video'
            render={(record) => {
              return record?.video && <VideoComponent url={record.video.url} />;
            }}
          />
          <ImageField source='video_thumbnail.url' label='Video Thumbnail' />
          <FunctionField
            sortBy='status'
            label='Status'
            render={(record) => {
              switch (record.status) {
                case 'active':
                  return (
                    <ChipField
                      record={record}
                      source='status'
                      variant='outlined'
                      style={{
                        textTransform: 'capitalize',
                        borderColor: '#44b700',
                      }}
                    />
                  );

                case 'inactive':
                  return (
                    <ChipField
                      record={record}
                      source='status'
                      variant='outlined'
                      style={{
                        textTransform: 'capitalize',
                        borderColor: '#e62424',
                      }}
                    />
                  );

                case 'pending':
                case 'pending-confirm':
                  return (
                    <ChipField
                      record={record}
                      source='status'
                      variant='outlined'
                      style={{
                        textTransform: 'capitalize',
                        borderColor: '#eead2d',
                      }}
                    />
                  );

                case 'suspended':
                  return (
                    <ChipField
                      record={record}
                      source='status'
                      variant='outlined'
                      style={{ textTransform: 'capitalize' }}
                    />
                  );

                default:
                  break;
              }
            }}
          />
          <BooleanField source='is_score_filtered' />
          <DateFieldShow label='Created At' source='created_at' />
          <DateFieldShow label='Updated At' source='updated_at' />
        </Tab>

        <Tab label='Professional Info'>
          <TextField label='About' source='about' />
          <TextField
            label='Typically Replies Within'
            source='typically_replies_within'
          />
          <ReferenceArrayField source='skills_ids' reference='skills'>
            <SingleFieldList>
              <ChipField source='name' />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField
            label='Years of Experience'
            source='additional_information.yearsOfExperience'
          />
        </Tab>

        <Tab label='Brands'>
          <SimpleShowLayout>
            <ReferenceArrayField
              source='brandings_ids'
              reference='brandings'
              fullWidth
            >
              <Datagrid>
                <ReferenceField
                  source='brand_id'
                  reference='brands'
                  label='Brand'
                  link={(record, reference) =>
                    `/${reference}/${record.brand_id}/show`
                  }
                >
                  <FunctionField
                    render={(record) => (
                      <AvatarContainer>
                        <UserAvatar url={record?.thumbnail_url || null} />
                        {record.name}
                      </AvatarContainer>
                    )}
                  />
                </ReferenceField>
              </Datagrid>
            </ReferenceArrayField>
          </SimpleShowLayout>
        </Tab>

        <Tab label='Address'>
          <ReferenceField
            source='country_id'
            reference='countries'
            label='Country'
          >
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField source='state_id' reference='states' label='State'>
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField source='city_id' reference='cities' label='City'>
            <TextField source='name' />
          </ReferenceField>
          <TextField source='address' />
          <TextField source='zip_code' label='Zip Code' fullWidth />
        </Tab>

        <Tab label='Localization'>
          <ReferenceField
            label='Primary language'
            source='language_id'
            reference='languages'
          >
            <TextField source='name' />
          </ReferenceField>

          <ReferenceArrayField source='languages_ids' reference='languages'>
            <SingleFieldList>
              <ChipField source='name' />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceField
            source='time_zone_id'
            reference='time_zones'
            label='Time zone'
          >
            <TextField source='name' />
          </ReferenceField>
        </Tab>

        <Tab label='Settings'>
          <TextField label='Sort Index' source='sort_index' />
          <UrlField label='Share Link' source='share_link' />
        </Tab>

        <Tab label='Reviews'>
          <SimpleShowLayout>
            <ReferenceArrayField
              source='reviews_ids'
              reference='reviews'
              fullWidth
            >
              <Datagrid>
                <ReferenceField
                  source='reviewer_id'
                  reference='users'
                  label='Reviewer Name'
                  link={(record, reference) =>
                    `/${reference}/${record.reviewer_id}/show`
                  }
                >
                  <FunctionField
                    render={(record) => (
                      <AvatarContainer>
                        <UserAvatar url={record?.thumbnail || null} />
                        {record.name}
                      </AvatarContainer>
                    )}
                  />
                </ReferenceField>
                <TextField source='comment' />
                <TextField source='rating' />
                <TextField source='status' />
              </Datagrid>
            </ReferenceArrayField>
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
