import React from 'react';
import {
  Create,
  email,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  ArrayInput,
  AutocompleteArrayInput,
  ImageField,
  ReferenceArrayInput,
  SimpleFormIterator,
  FileInput,
  NumberInput,
  FunctionField,
  FileField,
} from 'react-admin';
import { VideoComponent } from 'components/ImageContainer/VideoComponent';
import { FileUploaderPlaceholder } from '../../../components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';
import { usaMode } from '../../../config';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { DynamicAutocompleteInput } from '../../../components/Fields/Autocomplete/DynamicAutocompleteInput';

export const ExpertsCreate = (props) => (
  <Create {...props}>
    <TabbedForm
      variant='standard'
      margin='normal'
      toolbar={<CreateToolbar hideDelete={false} />}
      deletable
    >
      <FormTab label='Personal Details'>
        <TextInput
          source='nickname'
          label='Display Name'
          validate={required()}
          fullWidth
        />

        <TextInput
          source='email'
          label='Contact Email'
          validate={[email(), required()]}
          fullWidth
        />

        <TextInput
          source='phone_number'
          label='Phone Number'
          initialValue=''
          fullWidth
        />

        <FileInput
          source='thumbnail_file'
          accept='image/*'
          label='Thumbnail'
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <ImageField source='src' />
        </FileInput>

        <FileInput
          source='video_file'
          accept='video/*'
          label='Video'
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <FunctionField
            render={(record) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <FileField source='src' />
                  <VideoComponent
                    url={record?.src ? record.src : record.undefined}
                  />
                </div>
              );
            }}
          />
        </FileInput>

        <FileInput
          source='video_thumbnail_file'
          accept='image/*'
          label='Video Thumbnail'
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <ImageField source='src' />
        </FileInput>

        <SelectInput
          source='status'
          validate={required()}
          fullWidth
          choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'active', name: 'Active' },
            { id: 'inactive', name: 'Inactive' },
            { id: 'suspended', name: 'Suspended' },
          ]}
        />
      </FormTab>

      <FormTab label='Professional Info'>
        <TextInput source='about' multiline fullWidth />
        <TextInput
          label='Typically Replies Within'
          source='typically_replies_within'
          fullWidth
        />
        <NumberInput
          label='Years Of Experience'
          source='years_of_experience'
          fullWidth
        />
        <ReferenceArrayInput
          label='Skills'
          source='skills_ids'
          reference='skills'
          perPage={25}
          filterToQuery={(searchText) => ({
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth
        >
          <AutocompleteArrayInput
            matchSuggestion={() => true}
            optionText='name'
          />
        </ReferenceArrayInput>
      </FormTab>

      <FormTab label='Brands'>
        <ArrayInput label='' source='brandings'>
          <SimpleFormIterator>
            <ReferenceInput
              label='Brand'
              source='brand_id'
              reference='brands'
              perPage={25}
              filterToQuery={(searchText) => ({
                name: searchText,
                _autocomplete: true,
              })}
              sort={{ field: 'name', order: 'ASC' }}
              fullWidth
              allowEmpty
            >
              <AutocompleteInput
                matchSuggestion={() => true}
                optionText={(choice) =>
                  choice?.id ? `${choice.name} (${choice.id})` : 'None'
                }
              />
            </ReferenceInput>
            <ReferenceInput
              label='Experience Level'
              source='experience_level_id'
              reference='experience_levels'
              perPage={25}
              filterToQuery={(searchText) => ({
                name: searchText,
                _autocomplete: true,
              })}
              sort={{ field: 'name', order: 'ASC' }}
              fullWidth
              allowEmpty
            >
              <AutocompleteInput
                matchSuggestion={() => true}
                optionText={(choice) =>
                  choice?.id ? `${choice.name} (${choice.id})` : 'None'
                }
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label='Address'>
        <ReferenceInput
          label='Country'
          source='country_id'
          reference='countries'
          perPage={25}
          filterToQuery={(searchText) => ({
            iso: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth
          allowEmpty
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(choice) =>
              choice?.id ? `${choice.name} (${choice.iso})` : 'None'
            }
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              usaMode === formData.country_id && (
                <ReferenceInput
                  label='State'
                  source='state_id'
                  reference='states'
                  perPage={25}
                  filterToQuery={(searchText) => ({
                    iso: searchText,
                    name: searchText,
                    _autocomplete: true,
                  })}
                  sort={{ field: 'name', order: 'ASC' }}
                  variant='standard'
                  fullWidth
                  allowEmpty
                >
                  <AutocompleteInput
                    matchSuggestion={() => true}
                    optionText={(choice) =>
                      choice?.id ? `${choice.name} (${choice.iso})` : 'None'
                    }
                  />
                </ReferenceInput>
              )
            );
          }}
        </FormDataConsumer>
        <ReferenceInput
          label='City'
          source='city_name'
          reference='cities'
          perPage={25}
          filterToQuery={(searchText) => ({
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth
          allowEmpty
        >
          <DynamicAutocompleteInput />
        </ReferenceInput>
        <TextInput source='address' fullWidth />
        <TextInput source='zip_code' label='Zip Code' fullWidth />
      </FormTab>

      <FormTab label='localization'>
        <ReferenceInput
          label='Primary Language'
          source='language_id'
          reference='languages'
          perPage={25}
          filterToQuery={(searchText) => ({
            iso: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth
          allowEmpty
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(choice) =>
              choice?.id ? `${choice.name} (${choice.iso})` : 'None'
            }
          />
        </ReferenceInput>

        <ReferenceArrayInput
          label='Known Languages'
          source='languages_ids'
          reference='languages'
          perPage={25}
          filterToQuery={(searchText) => ({
            iso: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth
          allowEmpty
        >
          <AutocompleteArrayInput
            matchSuggestion={() => true}
            optionText='name'
          />
        </ReferenceArrayInput>

        <ReferenceInput
          label='Time Zone'
          source='time_zone_id'
          reference='time_zones'
          perPage={25}
          filterToQuery={(searchText) => ({
            iso: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'name', order: 'ASC' }}
          fullWidth
          allowEmpty
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(choice) =>
              choice?.id ? `${choice.name} (${choice.iso})` : 'None'
            }
          />
        </ReferenceInput>
      </FormTab>

      <FormTab label='Settings'>
        <NumberInput label='Sort Index' source='sort_index' fullWidth />
      </FormTab>

      <FormTab label='Credentials'>
        <ArrayInput label='' source='email_otp_credentials'>
          <SimpleFormIterator disableReordering>
            <TextInput
              label='Email'
              source='sid'
              validate={email()}
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
