import DataGridList from 'components/containers/List';
import React from 'react';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import Chip from '@material-ui/core/Chip';
import {
  ChipField,
  List,
  TextField,
  FunctionField,
  EmailField,
} from 'react-admin';
import { AvatarContainer } from '../../../components/Avatars/AvatarContainer';
import { ExpertAvatar } from 'components/Avatars';
import ExpertsFilter from './filter';
import ListActions from 'components/ListAction/ListActions';

export const ExpertList = (props) => {
  return (
    <List
      title='Experts'
      filters={<ExpertsFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={
        <ListActions listExporterType='Experts' hideCreateButton='true' />
      }
    >
      <DataGridList show edit>
        <FunctionField
          label='Name'
          sortBy='nickname'
          render={(record) => (
            <AvatarContainer>
              <ExpertAvatar
                url={record?.thumbnail_url ? record.thumbnail_url : null}
                status={record.status}
              />
              {record.nickname}
            </AvatarContainer>
          )}
        />
        <TextField label='Wallet Address' source='public_id' />
        <EmailField source='email' />
        <FunctionField
          sortBy='status'
          label='Status'
          render={(record) => {
            switch (record.status) {
              case 'active':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#44b700',
                    }}
                  />
                );

              case 'inactive':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#e62424',
                    }}
                  />
                );

              case 'pending':
              case 'pending-confirm':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#eead2d',
                    }}
                  />
                );

              case 'suspended':
                return (
                  <Chip
                    label='Soft Revoked'
                    variant='outlined'
                    style={{
                      borderColor: '#e67524',
                    }}
                  />
                );
              case 'banned':
                return <Chip label='Hard Revoked' variant='outlined' />;
              case 'initial':
                return <Chip label='Initial' variant='outlined' />;
              default:
                break;
            }
          }}
        />
      </DataGridList>
    </List>
  );
};
