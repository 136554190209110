import React from 'react';
import {
  Edit,
  email,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  FormDataConsumer,
  ImageField,
  FileInput,
  TextField,
} from 'react-admin';
import { FileUploaderPlaceholder } from '../../../components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';

const optionalStatusesGenerator = (status) => {
  switch (status) {
    case 'pending':
      return [{ id: 'pending', name: 'Pending' }];

    case 'active':
      return [
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Deactivate' },
        //{ id: "suspended", name: "Soft Revoke" },
        { id: 'banned', name: 'Hard Revoke' },
      ];
    case 'inactive':
      return [
        { id: 'inactive', name: 'Inactive' },
        //{ id: "suspended", name: "Soft Revoke" },
        { id: 'approved', name: 'Approve' },
        { id: 'declined', name: 'Decline' },
      ];
    case 'suspended':
      return [
        //{ id: "suspended", name: "Soft Revoked" },
        { id: 'inactive', name: 'Deactivate' },
        { id: 'approved', name: 'Approve' },
        { id: 'declined', name: 'Decline' },
      ];

    case 'banned':
      return [{ id: 'banned', name: 'Hard Revoked' }];

    default:
      return [
        { id: 'pending', name: 'Pending' },
        { id: 'active', name: 'Activate' },
        { id: 'inactive', name: 'Deactivate' },
        //{ id: "suspended", name: "Soft Revoke" },
        { id: 'banned', name: 'Hard Revoke' },
        { id: 'pending-confirm', name: 'Pending Confirmation' },
      ];
  }
};

export const ExpertsEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <TabbedForm
      variant='standard'
      margin='normal'
      toolbar={<EditToolbar hideDelete={true} />}
    >
      <FormTab label='Personal Details'>
        <TextField label='Wallet Address' source='public_id' />
        <TextInput
          source='nickname'
          label='Display Name'
          validate={required()}
          fullWidth
        />

        <TextInput
          source='email'
          label='Contact Email'
          validate={[email(), required()]}
          fullWidth
        />

        <FileInput
          source='thumbnail_file'
          accept='image/*'
          label='Thumbnail'
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <ImageField source='src' />
        </FileInput>

        <FormDataConsumer>
          {({ formData }) => (
            <SelectInput
              source='status'
              validate={required()}
              fullWidth
              choices={optionalStatusesGenerator(formData.status)}
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {() => (
            <SelectInput
              fullWidth
              source='is_score_filtered'
              validate={required()}
              label='Filter Score'
              choices={[
                { id: true, name: 'True' },
                { id: false, name: 'False' },
              ]}
            />
          )}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);
