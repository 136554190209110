import XLSX from 'xlsx';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import jsPDF from 'jspdf';

/**
 * Export data to an Excel file
 */
export function exportToExcel(exporterData) {
  const binaryWS = XLSX.utils.json_to_sheet(exporterData.data);
  const webBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(webBook, binaryWS, exporterData.fileName);
  XLSX.writeFile(webBook, `${exporterData.fileName}.xlsx`);
}

/**
 * Export data to an CSV file
 */
export function exportToCSV(exporterData) {
  jsonExport(
    exporterData.data,
    {
      headers: exporterData.headers,
    },
    (err, csv) => {
      downloadCSV(csv, exporterData.fileName);
    },
  );
}

/**
 * Export data to an PDF file
 */
export function exportToPDF(exporterData, resource) {
  const pdfHtmlResourceCustomize = (resource) => {
    return resource.replaceAll('/', '_');
  };
  const doc = new jsPDF('l', 'pt');
  doc.autoTable({
    theme: 'grid',
    html: `#${pdfHtmlResourceCustomize(resource)}-table`,
    styles: {
      minCellWidth: 40,
    },
    willDrawCell: (data) => {
      if (data.cell.text[0]?.includes('₪')) {
        data.cell.text[0] = data.cell.text[0].replace('₪', ' ILS ');
      }
      return !(
        data.cell.text[0] === 'Edit' ||
        data.cell.text[0] === 'Transaction' ||
        data.cell.text[0] === 'CLONE' ||
        data.cell.text[0] === 'VIEW' ||
        (data.cell.section === 'head' && data.cell.text[0] === '') ||
        (data.cell.section === 'body' &&
          data.cell.text[0] === '' &&
          data.column.raw.dataKey === 0) ||
        (data.cell.section === 'body' &&
          data.cell.text[0] === '' &&
          data.column.raw.dataKey === 1)
      );
    },
  });
  doc.save(`${exporterData.fileName}.pdf`);
}
